/* eslint-disable react/react-in-jsx-scope */
// AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
// import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
// import AWS from 'aws-sdk'
// import CryptoJS from 'crypto-js'
const AuthContext = createContext()

// const getSecretHash = (username, clientId, clientSecret) => {
//   const message = username + clientId
//   const hash = CryptoJS.HmacSHA256(message, clientSecret)
//   return CryptoJS.enc.Base64.stringify(hash)
// }

export const useAuth = () => {
  return useContext(AuthContext)
}

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  // AWS.config.region = process.env.REACT_APP_AWS_REGION
  // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //   IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  // })
  // const userPool = new AmazonCognitoIdentity.CognitoUserPool({
  //   UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  //   ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
  // })

  // const cognitoUser = userPool.getCurrentUser()
  // console.log('userPool', userPool)
  // console.log('cognitoUser', cognitoUser)
  const [currentUser, setCurrentUser] = useState(null)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        Auth.currentSession().then((user) => {
          const { payload } = user.getIdToken()
          if (payload && payload['cognito:groups']) {
            console.log(payload['cognito:groups'])
          }
        })
        setCurrentUser(user)
      } catch (error) {
        setCurrentUser(null)
      }
    }
    fetchUser()
  }, [])

  const confirmSignUp = async (username, code) => {
    try {
      await Auth.confirmSignUp(username, code)
    } catch (error) {
      console.log('error confirming sign up', error)
    }
  }

  const resendSignUp = async (username) => {
    try {
      await Auth.resendSignUp(username)
      console.log('code resent successfully')
    } catch (err) {
      console.log('error resending code: ', err)
    }
  }

  const signUp = async (username, password, email) => {
    // var params = {
    //   ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
    //   Password: password,
    //   Username: username,
    //   SecretHash: getSecretHash(
    //     username,
    //     process.env.REACT_APP_AWS_APP_CLIENT_ID,
    //     process.env.REACT_APP_AWS_APP_CLIENT_SECRET,
    //   ),
    //   UserAttributes: [
    //     {
    //       Name: 'email',
    //       Value: username,
    //     },
    //   ],
    // }

    // const provider = new CognitoIdentityServiceProvider({
    //   region: process.env.REACT_APP_AWS_REGION,
    // })

    // try {
    //   const res = provider.signUp(params)
    //   console.log('Signup success. Result: ', res)
    // } catch (e) {
    //   console.log('Signup fail. Error: ', e)
    // }

    // const userAttributes = []
    // const dataEmail = {
    //   Name: 'email',
    //   Value: username,
    // }
    // const validationData = {
    //   ClientMetadata: {
    //     secretHash: getSecretHash(
    //       username,
    //       process.env.REACT_APP_AWS_APP_CLIENT_ID,
    //       process.env.REACT_APP_AWS_APP_CLIENT_SECRET,
    //     ),
    //   },
    // }
    // const clientMetadata = {}
    // const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail)
    // userAttributes.push(attributeEmail)

    // userPool.signUp(
    //   username,
    //   password,
    //   userAttributes,
    //   validationData,
    //   (err, result) => {
    //     if (err) {
    //       console.error(err)
    //       return
    //     }
    //     console.log(`User sign up successfully: ${result.user.getUsername()}`)
    //   },
    //   clientMetadata,
    // )

    try {
      const user = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
        },
        autoSignIn: {
          enabled: true,
        },
      })
      setCurrentUser(user)
    } catch (error) {
      console.log('error signing up:', error)
    }
  }
  // const signIn = async (email, password) => {
  //   const authenticationData = {
  //     Username: email,
  //     Password: password,
  //     SecretHash: getSecretHash(
  //       email,
  //       process.env.REACT_APP_AWS_APP_CLIENT_ID,
  //       process.env.REACT_APP_AWS_APP_CLIENT_SECRET,
  //     ),
  //   }

  //   const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
  //     authenticationData,
  //   )
  //   const userData = {
  //     Username: email,
  //     Pool: userPool,
  //   }
  //   const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

  //   cognitoUser.authenticateUser(authenticationDetails, {
  //     onSuccess: (result) => {
  //       console.log('Access Token:', result.getAccessToken().getJwtToken())
  //       // setIsAuthenticated(true)

  //       // ユーザー情報を取得して、setUserInfoで設定
  //       cognitoUser.getUserAttributes((err, attributes) => {
  //         if (err) {
  //           console.error('err', err)
  //           return
  //         }
  //         const userInfo = attributes.reduce((acc, attribute) => {
  //           acc[attribute.Name] = attribute.Value
  //           return acc
  //         }, {})
  //         console.log('userInfo', userInfo)
  //         setCurrentUser(userInfo)
  //         return true
  //       })
  //     },
  //     onFailure: (err) => {
  //       console.log('onFailure')
  //       console.error(err)
  //       return false
  //     },
  //   })
  // }
  const signIn = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password)
      console.log('user', user)
      setCurrentUser(user)
      return true
    } catch (error) {
      console.error('Login failed:', error)
      return false
    }
  }

  const logout = async () => {
    try {
      await Auth.signOut()
      setCurrentUser(null)
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }
  const value = {
    currentUser,
    setCurrentUser,
    signIn,
    signUp,
    logout,
    resendSignUp,
    confirmSignUp,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
