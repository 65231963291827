import { createStore } from 'redux'
// import { configureStore } from '@reduxjs/toolkit'
// import rootReducer from './reducers'
const initialState = {
  sidebarShow: false,
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}
// const store = configureStore({ reducer: rootReducer })
const store = createStore(changeState)
export default store
