import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthProvider } from './AuthContext'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const Home = React.lazy(() => import('./views/home/Home'))

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const PageSignIn = React.lazy(() => import('./views/pages/pageSignIn/PageSignIn'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const RegisterForDoctor = React.lazy(() => import('./views/pages/register/RegisterForDoctor'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Email App
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))
const App = () => {
  return (
    <AuthProvider>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route element={<PageSignIn />} exact name="Login Page" path="/signIn" />
            <Route element={<Register />} exact name="Register Page" path="/register" />
            <Route element={<RegisterForDoctor />} exact name="Register Page" path="/register2" />
            <Route element={<Page404 />} exact name="Page 404" path="/404" />
            <Route element={<Page500 />} exact name="Page 500" path="/500" />
            <Route element={<EmailApp />} name="Email App" path="/apps/email/*" />
            {/* <Route path="/" name="Home" element={<Home />} /> */}
            <Route element={<DefaultLayout />} name="Home" path="*" />
            {/* <PrivateRoute path="/protected" element={<DefaultLayout />} /> */}
            {/* <PrivateRoute path="*" element={<DefaultLayout />} /> */}
          </Routes>
        </Suspense>
      </HashRouter>
    </AuthProvider>
  )
}
export default App
